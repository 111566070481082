import React, { Fragment } from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { COLORS } from "constants";
import { scrollableSide } from "components/common/Styles";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { getFormattedDateAndTime } from "utils/DateTimeUtils";
import SendIcon from "@mui/icons-material/Send";
import { capitalize } from "utils/WordsUtils";
import { getUserId } from "utils/GetUserDetail";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";

function ThreadsCommentSection({
  threadTitleForDisplay,
  threadUserForDisplay,
  threadPageUri,
  scrollableRef,
  totalCommentsPages,
  page,
  isFetchingComments,
  comments,
  newComment,
  callSendComment,
  loadLatestComments,
}) {
  const classes = scrollableSide();
  let navigate = useNavigate();

  const checkPreviousThread = (index, senderUserId, updatedAt) => {
    if (index === 0) {
      return true;
    }

    const previousComment = comments.state[comments.state.length - index];

    return (
      previousComment.senderUserId !== senderUserId ||
      getFormattedDateAndTime(previousComment.updatedAt) !== updatedAt
    );
  };

  return (
    <Grid container item xs={9.5}>
      <Grid
        container
        justifyContent="space-between"
        item
        xs={12}
        px={3}
        py={2}
        sx={{
          boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="font18b" color={COLORS.primary}>
            {threadTitleForDisplay.state}
          </Typography>
          <Typography variant="font18" color={COLORS.gray61}>
            {`${threadUserForDisplay.state}`}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          {threadPageUri.state && (
            <Tooltip title="Go to the recording page.">
              <IconButton
                size="small"
                onClick={(e) => {
                  navigate(threadPageUri.state);
                }}
              >
                <LinkIcon sx={{ color: COLORS.primary }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Refresh threads">
            <IconButton
              size="small"
              onClick={(e) => {
                loadLatestComments();
              }}
            >
              <AutorenewIcon sx={{ color: COLORS.primary }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Grid>
      <Grid
        ref={scrollableRef}
        item
        xs={12}
        className={classes.comments}
        py={2}
        px={3}
      >
        {totalCommentsPages.state > page.state && (
          <Box display="flex" justifyContent="center" alignContent="center">
            <Button
              disabled={isFetchingComments.state}
              variant="contained"
              size="small"
              color="primary"
              sx={{
                borderRadius: 10,
                backgroundColor: COLORS.primary50t,
                paddingX: 2,
              }}
              onClick={() => {
                page.update(page.state + 1);
              }}
            >
              <Typography variant="font12" color={COLORS.white}>
                Load more...
              </Typography>
              <KeyboardDoubleArrowUpIcon sx={{ color: COLORS.white }} />
            </Button>
          </Box>
        )}
        <Stack>
          {comments.state
            ?.slice()
            .reverse()
            .map((comment, index) => (
              <Box
                key={comment.threadMessageId}
                mt={
                  checkPreviousThread(
                    index,
                    comment.senderUserId,
                    getFormattedDateAndTime(comment.updatedAt)
                  ) && 2
                }
              >
                <Stack>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="flex-end"
                    justifyContent={
                      comment.senderUserId === getUserId() && "right"
                    }
                  >
                    {checkPreviousThread(
                      index,
                      comment.senderUserId,
                      getFormattedDateAndTime(comment.updatedAt)
                    ) &&
                      (comment.senderUserId === getUserId() ? (
                        <Typography variant="font16b" color={COLORS.primary}>
                          {`You`}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography variant="font16b" color={COLORS.primary}>
                            {`${capitalize(comment.senderFullName)}`}
                          </Typography>
                          <Typography variant="font13" color={COLORS.primary}>
                            {`[${comment.senderRole}]`}
                          </Typography>
                        </Fragment>
                      ))}
                    {checkPreviousThread(
                      index,
                      comment.senderUserId,
                      getFormattedDateAndTime(comment.updatedAt)
                    ) && (
                      <Typography variant="font12" color={COLORS.primary}>
                        {getFormattedDateAndTime(comment.updatedAt)}
                      </Typography>
                    )}
                  </Stack>
                  <Box
                    display="flex"
                    justifyContent={
                      comment.senderUserId === getUserId() && "right"
                    }
                  >
                    <Box
                      backgroundColor="rgba(39, 56, 145, 0.5)"
                      borderRadius={10}
                      p={1}
                      px={2}
                      display="flex"
                      justifyContent={
                        comment.senderUserId === getUserId() && "right"
                      }
                    >
                      <Typography
                        variant="font15"
                        color={COLORS.white}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {comment.message}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            ))}
        </Stack>
      </Grid>
      <Grid item xs={12} p={2} py={1}>
        <form>
          <Stack direction="row">
            <TextField
              fullWidth
              size="small"
              color="primary"
              placeholder="Message here"
              value={newComment.state}
              onChange={(e) => {
                newComment.update(e.target.value);
              }}
            />
            <IconButton
              disabled={!newComment.state}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                callSendComment();
              }}
              color="primary"
            >
              <SendIcon />
            </IconButton>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
}

export default ThreadsCommentSection;
