import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Stack,
  Link,
  Tooltip,
} from "@mui/material";
import { COLORS } from "constants";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate, useParams } from "react-router-dom";
import StatusProgress from "components/dashboard/AdminDashboard/sections/StatusProgress";
import DrawChart from "components/common/DrawChart";
import { getRecordingStatus, getRecordingLogs } from "service/BookService";
import RecordLogsTable from "./sections/RecordLogsTable";
import { isNotEmpty } from "utils/IsEmptyObject";

function RecordingStatus() {
  let navigate = useNavigate();
  const { bookId } = useParams();
  const recordingStatusData = useStateUpdate(null);
  const recordingLogs = useStateUpdate([]);

  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const orderBy = useStateUpdate("createdAt");
  const filterValue = useStateUpdate("");
  const isFetching = useStateUpdate(true);

  useEffect(() => {
    callGetRecordingStatus();
  }, []);

  useEffect(() => {
    callGetRecordingLogs();
  }, [rowsPerPage.state, page.state, orderBy.state, order.state]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  useEffect(() => {
    filterValue.state === "" && callGetRecordingLogs();
  }, [filterValue.state]);

  const callGetRecordingStatus = async () => {
    const result = await getRecordingStatus(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      recordingStatusData.update(data);
    }
  };

  const callGetRecordingLogs = async () => {
    const listParams = `?page=${page.state}&limit=${rowsPerPage.state}&sortBy=${
      orderBy.state
    }&sort=${order.state.toUpperCase()}&filter=${filterValue.state}`;

    const result = await getRecordingLogs(bookId, listParams);
    if (result.status === 200) {
      isFetching.update(false);
      const { data } = result.data;
      pageCount.update(data.pagination.totalPages);
      recordingLogs.update(data.auditLogs);
    }
  };

  const getLineChartData = () => {
    const recordingDetails =
      recordingStatusData.state?.paragraphDetails.recordingDetails;
    let lineChartData = [];
    if (isNotEmpty(recordingDetails ?? {})) {
      Object.keys(recordingDetails).forEach((key) => {
        lineChartData.push([key, recordingDetails[key]]);
      });
    } else {
      lineChartData.push([0, 0]);
    }
    lineChartData.push(["Date", "Recordings"]);
    return lineChartData.reverse();
  };

  const bookDetails = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box py={4} display="flex" justifyContent="center">
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <Typography variant="font15" noWrap>
                  Book name:
                </Typography>
                <Typography variant="font15b" color={COLORS.primary} noWrap>
                  {recordingStatusData.state?.bookName}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="font15" noWrap>
                  Author name:
                </Typography>
                <Typography variant="font15b" color={COLORS.primary} noWrap>
                  {recordingStatusData.state?.authorName}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="font15">Recording by:</Typography>
                <Typography variant="font15b" color={COLORS.primary}>
                  {recordingStatusData.state?.audioRecordedBy.join(", ")}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const totalPages = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
            <Stack spacing={2}>
              <Typography variant="font15b" color={COLORS.primary} noWrap>
                Total pages
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt={{ xs: 1, md: 2 }}
              >
                <Link
                  href=""
                  variant="font35b"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/voices/${bookId}`);
                  }}
                >
                  {recordingStatusData.state?.pageDetails.noOfPages}
                </Link>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const totalParagraphs = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
            <Stack spacing={2}>
              <Typography variant="font15b" color={COLORS.primary} noWrap>
                Total paragraphs
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt={{ xs: 1, md: 2 }}
              >
                <Link
                  href=""
                  variant="font35b"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/voices/${bookId}`);
                  }}
                >
                  {recordingStatusData.state?.paragraphDetails.noOfParagraphs}
                </Link>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const recordingLineGraph = () => {
    return (
      <Grid container item xs={12}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box p={2}>
            <Typography variant="font15b" color={COLORS.primary} noWrap>
              Recording done so far
            </Typography>
            <Box pt={3}>
              <DrawChart
                chartType="Line"
                width="99%"
                data={getLineChartData()}
                options={{
                  legend: { position: "none" },
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const pagesRecorded = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box p={5}>
            {recordingStatusData.state && (
              <Tooltip
                title={
                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="font12">Pages recorded:</Typography>
                      <Typography variant="font13b">
                        {
                          recordingStatusData.state.pageDetails
                            .noOfRecordedPages
                        }
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="font12">Total pages:</Typography>
                      <Typography variant="font13b">
                        {" "}
                        {recordingStatusData.state.pageDetails.noOfPages}
                      </Typography>
                    </Stack>
                  </Stack>
                }
              >
                <Box>
                  <StatusProgress
                    percentage={
                      recordingStatusData.state.pageDetails.noOfRecordedPages /
                      recordingStatusData.state.pageDetails.noOfPages
                    }
                    count={
                      recordingStatusData.state.pageDetails.noOfRecordedPages ??
                      0
                    }
                    pathColor={"#98FB98"}
                    isRecordingStatus
                    label={"Pages recorded"}
                    {...{ bookId }}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Paper>
      </Grid>
    );
  };

  const percentageCompleted = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box>
            {recordingStatusData.state && (
              <DrawChart
                chartType="PieChart"
                width="100%"
                data={[
                  ["Task", "Count"],
                  [
                    "Paragraphs recorded",
                    recordingStatusData.state.paragraphDetails
                      .noOfRecordedParagraphs,
                  ],
                  [
                    "Paragraphs not recorded",
                    recordingStatusData.state.paragraphDetails.noOfParagraphs -
                      recordingStatusData.state.paragraphDetails
                        .noOfRecordedParagraphs,
                  ],
                ]}
                options={{
                  pieHole: 0.4,
                  legend: { position: "bottom" },
                }}
              />
            )}
          </Box>
        </Paper>
      </Grid>
    );
  };

  const paragraphsRecorded = () => {
    return (
      <Grid container item xs={12} sm={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box p={5}>
            {recordingStatusData.state && (
              <Tooltip
                title={
                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="font12">
                        Paragraphs recorded:
                      </Typography>
                      <Typography variant="font13b">
                        {
                          recordingStatusData.state.paragraphDetails
                            .noOfRecordedParagraphs
                        }
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="font12">
                        Total paragraphs:
                      </Typography>
                      <Typography variant="font13b">
                        {
                          recordingStatusData.state.paragraphDetails
                            .noOfParagraphs
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                }
              >
                <Box>
                  <StatusProgress
                    percentage={
                      recordingStatusData.state.paragraphDetails
                        .noOfRecordedParagraphs /
                      recordingStatusData.state.paragraphDetails.noOfParagraphs
                    }
                    count={
                      recordingStatusData.state.paragraphDetails
                        .noOfRecordedParagraphs ?? 0
                    }
                    pathColor={"#87CEEB"}
                    isRecordingStatus
                    label={"Paragraphs recorded"}
                    {...{ bookId }}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Paper>
      </Grid>
    );
  };

  return (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Recording status
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {bookDetails()}
        {totalPages()}
        {totalParagraphs()}
        {recordingLineGraph()}
        {pagesRecorded()}
        {percentageCompleted()}
        {paragraphsRecorded()}
        <RecordLogsTable
          {...{
            callGetRecordingLogs,
            isFetching,
            filterValue,
            rowsPerPage,
            page,
            pageCount,
            order,
            orderBy,
            recordingLogs,
            bookId,
          }}
        />
      </Grid>
    </Box>
  );
}

export default RecordingStatus;
