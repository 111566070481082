import React, { useContext, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { primaryButtonHover } from "components/common/Styles";
import OptimizeIcon from "assets/images/optimizeIcon.png";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { BooksRecordedContext } from "context/context";
import { useIsAuthor } from "utils/GetUserDetail";
import { THREAD_TYPES } from "constants";
import { getUserId } from "utils/GetUserDetail";
import { sendComment } from "service/ChatService";

function ConfirmButton({ downloadFormat, disabled }) {
  const {
    status,
    chapters,
    isConfirmBtnDisable,
    isWavePlaying,
    pauseAudio,
    handleDownload,
    bookDetails,
    setConfirmModalData,
    callUpdateBookStatus,
  } = useContext(BooksRecordedContext);

  const callCreateThread = async (message) => {
    let data = getThreadDetails(message);
    let response = await sendComment(data);
    if (response.status === 200) {
      callUpdateBookStatus(BookStatusCodes.bookRecording);
    }
  };

  const getThreadDetails = (message) => {
    const { bookId, bookName } = bookDetails.state; //
    return {
      title: `Optimization rejected: [${bookId}]${bookName}`,
      description: "",
      message: message.trim(),
      receiverUserId: getUserId(),
      threadType: THREAD_TYPES.other,
      pageUri: ``,
    };
  };

  const isAuthor = useIsAuthor();

  const handleConfirmButton = async () => {
    isWavePlaying.state && pauseAudio();
    let modalTitle, modalConfirmationMessage, updateStatusCode;
    // const chapters = bookDetails.state.chapters || [];

    switch (status) {
      case BookStatusCodes.optimizationInProgress: {
        // Check if all chapters are accepted
        const anyRejected = chapters.state.some(
          (chapter) => chapter.isRejected === "rejected"
        );

        if (anyRejected) {
          // Revert the book status if any are rejected
          await callUpdateBookStatus(BookStatusCodes.bookRecording);
          return; // Exit early to prevent showing the modal
        } else {
          // Show modal if all are accepted
          modalTitle = "Complete Optimization";
          modalConfirmationMessage =
            "Are you sure you want to complete the audio optimization?";
          updateStatusCode = BookStatusCodes.audioOptimized;
        }

        break;
      }
      case BookStatusCodes.audioOptimized: {
        const anyRejected = chapters.state.some(
          (chapter) => chapter.isRejected === "rejected"
        );

        if (anyRejected) {
          if (isAuthor) {
            const rejectedChapters = chapters.state.filter(
              (chapter) => chapter.isRejected === "rejected"
            );
            let message = "";
            if (rejectedChapters.length > 0) {
              message = "These chapters are rejected:\n";
              rejectedChapters.forEach((chapter) => {
                message += `Chapter Name: ${chapter.chapterName}\nReason: ${chapter.rejectionReason}\n`;
              });

              console.log(message);
            }

            await callCreateThread(message);

            return;
          } else {
            await callUpdateBookStatus(BookStatusCodes.bookRecording);
          }
          // Revert the book status if any are rejected

          return; // Exit early to prevent showing the modal
        } else {
          // Show modal if all are accepted
          modalTitle = "Ready for production";
          modalConfirmationMessage =
            "Are you sure you want to mark this as ready for production?";
          updateStatusCode = BookStatusCodes.readyForProduction;
        }

        break;
      }
      case BookStatusCodes.readyForProduction:
        modalTitle = "Book completed";
        modalConfirmationMessage =
          "Are you sure you want to mark this as completed?";
        updateStatusCode = BookStatusCodes.completed;
        break;
      case BookStatusCodes.completed:
        handleDownload(downloadFormat);
        break;

      default:
        return;
    }

    if (status !== BookStatusCodes.completed) {
      setConfirmModalData(
        OptimizeIcon,
        modalTitle,
        modalConfirmationMessage,
        "Yes",
        "Cancel",
        () => {
          callUpdateBookStatus(updateStatusCode);
        },
        null
      );
    }
  };

  const getButtonLabel = () => {
    switch (status) {
      case BookStatusCodes.optimizationInProgress:
        return "Submit";
      case BookStatusCodes.audioOptimized:
        return "Submit";
      case BookStatusCodes.readyForProduction:
        return "Complete book";
      case BookStatusCodes.completed:
        return `Download ${downloadFormat} zip file`;

      default:
        return "";
    }
  };

  return (
    <Button
      disabled={isConfirmBtnDisable.state || disabled}
      variant="outlined"
      sx={primaryButtonHover}
      onClick={handleConfirmButton}
    >
      <Typography variant="font15b" noWrap>
        {getButtonLabel()}
      </Typography>
    </Button>
  );
}

export default ConfirmButton;
