import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Checkbox,
  Alert,
  AlertTitle,
} from "@mui/material";
import { COLORS } from "constants";
import Magnify from "assets/images/mg.png";
import { pageNumberIndicator, pageShowingCard } from "components/common/Styles";
import { getImageUrl } from "utils/GetUrl";
import ImageComponent from "components/common/ImageComponent";
import { GroupingPageContext } from "context/context";

function GroupingRightScrollable() {
  const {
    pagesToDisplay,
    isShowingGroupedPages,
    viewPage,
    selectedFirstPage,
    selectedLastPage,
    isAutoGrouping,
  } = useContext(GroupingPageContext);

  const classes = pageNumberIndicator();
  const classes2 = pageShowingCard();

  return (
    <Grid container rowSpacing={5} columnSpacing={8}>
      {isAutoGrouping.state ? (
        <Grid item>
          <Box
            borderRadius={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={5}
          >
            <Alert
              severity="warning"
              style={{
                boxShadow: "2px -2px 10px -2px rgba(0, 0, 0, 0.2)",
              }}
            >
              <AlertTitle>Auto Grouping...</AlertTitle>
              Please wait while the pages are being grouped automatically.
            </Alert>
          </Box>
        </Grid>
      ) : pagesToDisplay.state.length > 0 ? (
        pagesToDisplay.state.map((page, index) => (
          <Grid item key={page.pageId}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                // backgroundImage: `url("${getImageUrl(page.pageImageUrl)}")`,
                cursor: !isShowingGroupedPages.state && "pointer",
                position: "relative",
              }}
              className={classes2.groupingPageRight}
              onClick={() => {
                if (!isShowingGroupedPages.state) {
                  if (index === 0 && selectedFirstPage.state >= 0) {
                    selectedFirstPage.update();
                    selectedLastPage.update();
                  } else if (selectedLastPage.state === page.pageNumber) {
                    selectedLastPage.update(
                      pagesToDisplay.state[index - 1].pageNumber
                    );
                  } else {
                    selectedFirstPage.update(
                      pagesToDisplay.state[0].pageNumber
                    );
                    selectedLastPage.update(page.pageNumber);
                  }
                }
              }}
            >
              <Box className={classes.groupingPageLeft}>
                <Typography variant="font12b" color={COLORS.white}>
                  {page.pageNumber}
                </Typography>
              </Box>
              <Grid container>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  style={{ position: "absolute" }}
                >
                  {!isShowingGroupedPages.state && (
                    <Checkbox
                      size="small"
                      checked={
                        page.pageNumber >= selectedFirstPage.state &&
                        page.pageNumber <= selectedLastPage.state
                      }
                    />
                  )}
                </Grid>

                <ImageComponent
                  src={getImageUrl(page.pageImageUrl)}
                  style={{
                    width: "100%",
                    height: `100%`,
                    borderRadius: "10px",
                  }}
                  alt="page-image"
                />

                <Grid
                  container
                  alignItems="flex-end"
                  style={{ position: "absolute", bottom: 0 }}
                >
                  <Box
                    width="100%"
                    borderRadius=" 0 0 10px 10px "
                    bgcolor={COLORS.gray}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      viewPage(getImageUrl(page.pageImageUrl));
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <img
                        src={Magnify}
                        style={{ width: "12px", height: "12px" }}
                        alt="View page"
                      />
                      <Typography variant="font12">View page</Typography>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))
      ) : (
        <Grid item>
          <Box
            borderRadius={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={5}
          >
            <Alert
              severity="success"
              style={{
                boxShadow: "2px -2px 10px -2px rgba(0, 0, 0, 0.2)",
              }}
            >
              <AlertTitle>Completed</AlertTitle>
              All pages are grouped into chapters. Click on the{" "}
              <strong>Complete</strong> button to proceed.
            </Alert>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default GroupingRightScrollable;
