import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";

import { MOBILE_MAX_WIDTH } from "constants/otherConstant";
import { useStateUpdate } from "UseStateUpdate";
import Info from "assets/images/info.png";
import {
  ConfirmModal,
  WarningModal,
  AudioUploadModal,
} from "components/modals";
import { checkBookStatus } from "utils/Checkbookstatus";
import AccessDenied from "components/common/AccessDenied";
import Toast from "components/common/Toast";
import ChapterTable from "components/common/ChapterTable";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  BookName,
  PageImage,
  PageDetails,
  ConfirmButton,
} from "./ChildComponents"; // Remove RejectButton import
import { getAudioUrl } from "utils/GetUrl";
import { RetailSampleAudioUploadModal } from "components/modals";
import RejectMessageModal from "components/modals/RejectMessageModal";
import { BooksRecordedContext } from "context/context";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import InstructionModal from "components/modals/InstructionModal";

const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

function BooksRecorded() {
  const {
    bookId,
    fetchBookChaptersWithAudio,
    status,
    bookDetails,
    selectedChapter,
    openRejectMessageModal,
    callUpdateBookStatus,
    openConfirmModal,
    confirmModalData,
    openAudioBookModal,
    currentChapterToUpload,
    retailSampleAudioUploadModal,
    waveSurfer,
    chapters,
    checkOptimizedAudio,
    isWavePlaying,
    currentWaveTime,
    currentUrlToPlay,
    loadWaveSurfer,
  } = useContext(BooksRecordedContext);

  const isInstructionModalOpen = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const warningModalMessage = useStateUpdate("");
  const navigateTo = useStateUpdate(null);
  const isFetching = useStateUpdate(true);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  useEffect(() => {
    if (status === BookStatusCodes.audioOptimized) {
      isInstructionModalOpen.update(true);
    }
  }, [status]);
  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      // !isAuthor && fetchBookChaptersWithAudio();
      fetchBookChaptersWithAudio();
    }
    return () => {
      waveSurfer?.destroy();
    };
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        status,
        bookDetails.state,
        bookDetails.state.chapters.length
      );

      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);

      if (!bookStatus.isOpenModal) {
        selectedChapter.update(bookDetails.state.chapters[0]);
        chapters.update(bookDetails.state.chapters);
        isFetching.update(false);
      }
    }
  }, [bookDetails.state]);

  useEffect(() => {
    chapters.state && checkOptimizedAudio();
  }, [chapters.state]);

  useEffect(() => {
    waveSurfer?.destroy();
    isWavePlaying.update(false);
    currentWaveTime.update("00:00:00");
  }, [selectedChapter.state]);

  useEffect(() => {
    if (currentUrlToPlay.state) {
      waveSurfer?.destroy();
      loadWaveSurfer(
        getAudioUrl(currentUrlToPlay.state) + "?t=" + new Date().getTime()
      );
    }
  }, [currentUrlToPlay.state]);

  return false ? (
    <AccessDenied />
  ) : (
    <Box pt={5} px={10}>
      <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
      <RejectMessageModal
        bookName={bookDetails.state?.bookName}
        {...{ openRejectMessageModal, callUpdateBookStatus, bookId }}
      />
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />
      <AudioUploadModal
        open={openAudioBookModal}
        bookId={currentChapterToUpload.state.bookId}
        chapterId={currentChapterToUpload.state.chapterId}
        fetchDetails={fetchBookChaptersWithAudio}
        {...{ severity, toastMessage, toastOpen }}
      />
      <RetailSampleAudioUploadModal
        open={retailSampleAudioUploadModal}
        fetchDetails={fetchBookChaptersWithAudio}
        {...{ bookId }}
      />
      {/* Instruction Modal */}
      <InstructionModal
        open={isInstructionModalOpen.state} // Use the state property for visibility
        onClose={() => isInstructionModalOpen.update(false)} // Close modal with `update`
        instructions="This is where you can provide some important instructions for the user before they proceed to the audio upload page."
      />

      <Grid container spacing={4} mb={5}>
        <BookName bookName={bookDetails.state?.bookName} />
        {!isFetching.state && (
          <Fragment>
            <Grid container item xs={10}>
              <PageImage
                pageImageUrl={selectedChapter?.state?.pages[0]?.pageImageUrl}
              />
              <PageDetails />
            </Grid>

            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {bookDetails.state.compressedFileUrl ||
                status !== BookStatusCodes.completed ? (
                  status === BookStatusCodes.completed ? (
                    <Stack spacing={2}>
                      <ConfirmButton downloadFormat="ACX" />
                      <ConfirmButton
                        downloadFormat="AAC"
                        disabled={!bookDetails.state.aacZipUrl}
                      />
                    </Stack>
                  ) : (
                    <>
                      <ConfirmButton />
                    </>
                  )
                ) : (
                  <Tooltip title="Click to refresh.">
                    <Button variant="text" onClick={fetchBookChaptersWithAudio}>
                      <AutorenewIcon />
                      <Typography variant="font15b" noWrap>
                        Generating zip file
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
                {status === BookStatusCodes.audioOptimized && (
                  <Tooltip title="info">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        //
                        isInstructionModalOpen.update(true);
                      }}
                    >
                      <img src={Info} alt="Info" width={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {/* Remove RejectButton here */}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {!isFetching.state && (
                <ChapterTable
                  inOptimization={
                    status === BookStatusCodes.optimizationInProgress
                  }
                  isCompleted={
                    status === BookStatusCodes.audioOptimized ||
                    status === BookStatusCodes.readyForProduction ||
                    status === BookStatusCodes.completed
                  }
                  fetchBookDetails={fetchBookChaptersWithAudio}
                />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen.state}
      />
    </Box>
  );
}

export default BooksRecorded;
