import { apiCall, apiCallWithFormData } from "./AuthProvider";

const SERVICE_URL = "book-service/";

export const createbook = async (body) => {
  const url = `${SERVICE_URL}books`;
  return apiCall("post", url, body);
};

export const updateBook = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}`;
  return apiCall("put", url, body);
};

export const getBookDetails = async (bookId) => {
  const url = `${SERVICE_URL}books/${bookId}`;
  //const url = `${SERVICE_URL}books/${bookId}/pages/chapter`;
  return apiCall("get", url);
};

export const listBooks = async (searchParams) => {
  const url = `${SERVICE_URL}${searchParams}`;
  return apiCall("get", url);
};

export const getChapters = async (bookId) => {
  const url = `${SERVICE_URL}books/${bookId}/chapters`;
  return apiCall("get", url);
};

export const createChapter = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/chapters`;
  return apiCall("post", url, body);
};

export const AutoGroupPages = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/pages/group`;
  return apiCall("post", url, body);
};

export const updateBookStatus = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/status/update`;
  return apiCall("put", url, body);
};

export const resetChapter = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/chapters/reset`;
  return apiCall("put", url, body);
};

export const assignUser = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/assign`;
  return apiCall("put", url, body);
};

export const groupChapters = async (body) => {
  const url = `${SERVICE_URL}books/${body}/group`;
  return apiCall("post", url, body);
};

export const getChaptersWithAudio = async (bookId) => {
  const url = `${SERVICE_URL}books/${bookId}/chapter-audio`;
  return apiCall("get", url);
};

export const getBookDetailsWithAuthor = async (bookId) => {
  const url = `${SERVICE_URL}books/${bookId}/author`;
  return apiCall("get", url);
};

export const copyChapterAudio = async (bookId, chapterId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/chapters/${chapterId}/audio/optimize`;
  return apiCall("put", url, body);
};

export const getRecordingStatus = async (bookId) => {
  const url = `${SERVICE_URL}books/${bookId}/status`;
  return apiCall("get", url);
};

export const getRecordingLogs = async (bookId, params) => {
  const url = `${SERVICE_URL}books/${bookId}/logs${params}`;
  return apiCall("get", url);
};

export const uploadPdf = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/upload`;
  return apiCallWithFormData("put", url, body);
};

export const uploadChapterAudio = async (bookId, chapterId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/chapters/${chapterId}/audio/upload`;
  return apiCallWithFormData("put", url, body);
};
export const uploadRetailSampleAudio = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/retail-sample/upload`;
  return apiCallWithFormData("put", url, body);
};

export const updateRetailSample = async (bookId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/retail-sample`;
  return apiCall("put", url, body);
};

export const assignAuthors = async (bookId, body, isAssign) => {
  const url = `${SERVICE_URL}books/${bookId}/assign?isAssign=${isAssign}`;
  return apiCall("post", url, body);
};

export const chapterWiseRejection = async (bookId, chapterId, body) => {
  const url = `${SERVICE_URL}books/${bookId}/chapter/${chapterId}/reject`;
  return apiCall("put", url, body);
};

export const getBookDetailsForRecording = async (bookId) => {
  //const url = `${SERVICE_URL}books/${bookId}`;
  const url = `${SERVICE_URL}books/${bookId}/pages/chapter`;
  return apiCall("get", url);
};
