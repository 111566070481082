import React from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Button,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink for navigation

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 800 },
  bgcolor: "#f7f9fc",
  boxShadow: 24,
  p: 5,
  borderRadius: 3,
  textAlign: "center",
};

const buttonStyle = {
  fontSize: "1rem",
  padding: "10px 20px",
  bgcolor: "rgba(34,51,120,1)",
  color: "#ffffff",
  "&:hover": {
    bgcolor: "rgba(34,51,120,1)",
  },
};

const closeButtonStyle = {
  position: "absolute",
  top: 16,
  right: 16,
  bgcolor: "#ffffff",
  color: "rgba(34,51,120,1)",
  "&:hover": {
    bgcolor: "#f7f9fc",
  },
};

function MicDeniedModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton onClick={onClose} sx={closeButtonStyle}>
          <CloseIcon fontSize="large" />
        </IconButton>
        <MicIcon
          sx={{
            fontSize: 100,
            color: "rgba(34,51,120,1)",
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: "rgba(34,51,120,1)",
            fontWeight: "bold",
            mb: 3,
            fontSize: "1.75rem",
          }}
        >
          Please allow Microphone permission in browser settings to record audio
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 4, fontSize: "1.25rem", color: "#4a4a4a" }}
        >
          It seems that you haven’t given mic permission to record the audio.
          MyAudiobookRecorder needs mic permission to complete your audiobook
          journey.
          <br />
          <br />
          Please go through the link to understand how you can enable the same.
          <br />
          <Link
            component={RouterLink}
            to="/micpermissionsFAQ" // Replace with your desired route
            sx={{
              color: "rgba(34,51,120,1)",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Click here for more information.
          </Link>
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={buttonStyle}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default MicDeniedModal;
